/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Swiper
@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import '~swiper/scss/navigation';
@import '~@ionic/angular/css/ionic-swiper';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Rubik:wght@500;700&display=swap');

body {
    font-size: 15px;
}

ion-toolbar {
    --border-color: transparent;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    ion-back-button,
    ion-button,
    ion-menu-button {
        --background: var(--ion-card-background) !important;
    }
    ion-title {
        padding-inline: 45px;
    }
}

ion-menu {
    --width: 100%;
}

ion-popover {
    --width: 300px;
    --backdrop-opacity: .5;
}

ion-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.6) !important;
    ion-toolbar {
        --padding-start: 0;
        --padding-end: 0;
    }
    ion-footer {
        ion-toolbar {
            --padding-top: 0;
        }
    }
    ion-item {
        ion-textarea {
            // min-height: 150px;
            textarea {
                // min-height: 150px !important;
            }
        }
    }
    &.small {
        --height: 400px;
        --width: 400px;
        --max-width: 90%;
        --max-height: 90%;
        --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
        // --backdrop-opacity: var(--ion-backdrop-opacity, 0.6) !important;
        --border-radius: 10px;
        ion-footer ion-toolbar:last-of-type {
            padding-bottom: 0px;
        }
    }
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-family: var(--custom-font), 'Rubik', sans-serif;
}

ion-chip {
    font-size: 15px;
    font-weight: 500;
    border-radius: 24px;
}

ion-card.ios {
    ion-card-content.ios {
        font-size: 14px;
    }
    h2 {
        font-size: 15px;
        font-weight: 500;
    }
    ion-list {
        border-radius: 8px;
    }
}


.edit-images {
    .swiper-slide {
        width: 150px !important;
    }
    .swiper-pagination {
        bottom: 40px;
    }
}

.product-swiper {
    margin-left: 0;
    .swiper-slide {
        width: 400px !important;
        max-width: 95vw;
        &.add {
            width: auto !important;
        }
    }
    ion-card {
        height: 140px;
        max-height: 140px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.onboarding-swiper {
    .swiper-slide {
        width: 920px !important;
        max-width: 90%;

    }
}

.category-swiper {
    .swiper-slide {
        max-width: 95%;
        width: fit-content;
    }
}

.swiper-button-next {
    color: var(--ion-color-primary);
}

.swiper-button-prev  {
    color: var(--ion-color-primary);
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    display: none;
}

.onboarding-swiper-navigation {
    .swiper-slide:not(.swiper-slide-active) {
        ion-grid {
            opacity: 0.1;
        }
    }

    .swiper-pagination-bullet-active {
        // background: white;
        background: var(--ion-color-text-onboarding);
    }
    
    .swiper-button-next {
        color: white;
        --swiper-navigation-size: 18px;
        height: 50px;
        width: 50px;
        background: var(--ion-color-gray);
        border-radius: 50%;
    }
    .swiper-button-prev {
        color: white;
        --swiper-navigation-size: 18px;
        height: 50px;
        width: 50px;
        background: var(--ion-color-gray);
        border-radius: 50%;
    }
}

.onboarding-swiper-navigation-boxes {
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        display: flex;
        opacity: 0.3;
    }
    .swiper-slide:not(.swiper-slide-active) {
        ion-grid {
            opacity: 0.1;
        }
    }

    .swiper-wrapper {
        width: 350px !important;
        max-width: 100%;
        .swiper-slide {
            // margin-right: 0 !important;
            justify-content: left;
        }
    }

    .swiper-pagination-bullet-active {
        background: white;
    }
    
    .swiper-button-next {
        color: var(--ion-color-primary-contrast);
        --swiper-navigation-size: 18px;
        height: 34px;
        width: 34px;
        background: var(--ion-color-primary);
        border-radius: 20px;
        top: 90%;
        left: 52%
    }
    .swiper-button-prev {
        color: var(--ion-color-primary-contrast);
        --swiper-navigation-size: 18px;
        height: 34px;
        width: 34px;
        background: var(--ion-color-primary);
        border-radius: 20px;
        top: 90%;
        left: 40%;
    }
}

.custom-font {
    font-family: var(--custom-font), 'Rubik', sans-serif;
}

ion-icon[name="information-circle-outline"]:not(.info-options) {
    height: 20px !important;
    width: 20px !important;
  }

ion-item {
    ion-textarea {
        pointer-events: none;
    }
    // ion-input{
    //     pointer-events: none;
    // }
}

.spinner {
    path {
        transition: all 1s ease;
    }
}

.delete-product-alert {
    #cancel-button {
        color: white;
    }
    #confirm-button {
        color: #F56767;
    }
}

ion-alert {
    #cancel-button {
        color: white;
    }
    #confirm-button {
        color: #F56767;
    }
    #confirm-button-done {
        color: var(--ion-color-success);
    }
}

.choose_btn path {
    fill: var(--ion-color-primary);
}

.choose_btn_txt {
    fill: var(--ion-color-primary-contrast);
}

.add_btn path {
    fill: var(--ion-color-primary);
}

.add_btn_txt {
    fill: var(--ion-color-primary-contrast);
}

.fromPrice {
    display: flex;
    p { 
        border-radius: 4px;
        font-size: 13px;
        padding-left: 2px;
        padding-right: 2px;
        &.warning {
            background: var(--ion-color-warning);
            color: black;
        }
        &.danger {
            background: var(--ion-color-danger);
            color: white;
        }
    }
    ion-text {
        font-size: 13px;
    }
}

.fromPrice-product {
    display: flex;
    p { 
        border-radius: 4px;
        font-size: 15px;
        padding-left: 5px;
        padding-right: 5px;
        &.warning {
            background: var(--ion-color-warning);
            color: black;
        }
        &.danger {
            background: var(--ion-color-danger);
            color: white;
        }
    }
    ion-text {
        font-size: 15px;
    }
}
.sale-tag {
    display: inline;
    border-radius: 4px;
    font-size: 15px;
    padding-left: 5px;
    padding-right: 5px;
    &.warning {
        background: var(--ion-color-warning);
        color: black;
    }
    &.danger {
        background: var(--ion-color-danger);
        color: white;
    }
}
